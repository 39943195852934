<template>
  <el-dialog
    v-loading="loading"
    top="10vh"
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    custom-class="rounded balance-payment-modal"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <span>
            <h2 class="text-typography-primary">{{ translate('header') }}</h2>
          </span>
          <span>
            <el-dropdown trigger="click" @command="handleAction($event)">
              <Button type="text" class="p-0 text-typography-primary actions-btn">
                <KebabIcon />
              </Button>
              <el-dropdown-menu slot="dropdown" class="text-typography-primary">
                <el-dropdown-item command="selectAll">
                  {{ translate('actions.selectAll') }}
                </el-dropdown-item>
                <el-dropdown-item command="unselectAll">
                  {{ translate('actions.unselectAll') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
              <CloseIcon />
            </Button>
          </span>
        </div>
        <span>
          <p class="text-typography-primary">
            {{ supplier.name }}
          </p>
        </span>
      </div>
    </template>
    <div class="h-100">
      <div class="overflow-auto h-100">
        <Table
          :columns="columns"
          :data="tableData"
          :row-selection.sync="rowSelection"
          row-click-selection-toggle
          show-index
          border
          rounded
          hover
          class="mb-4"
          small
        >
          <template #cell-index="{ rowIndex }">
            {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
          </template>
          <template #cell-billingDate="{ rowData: { billingDate } }">
            <p>
              {{ formatDate(billingDate) }}
            </p>
          </template>
          <template #cell-eventType="{ rowData: { documentType } }">
            <p v-if="documentType" class="text-typography-primary">
              {{ $t(`document.exports.schema.type.fullName.${documentType}`) }}
            </p>
          </template>
          <template
            #cell-reference="{
              rowData: {
                reference: { documentNumber, documentId },
              },
            }"
          >
            <Button type="link" class="fs-normal" @click.stop="selectedDocumentId = documentId">
              <p>{{ documentNumber }}</p>
            </Button>
          </template>
          <template #cell-debit="{ rowData: { debit, unpaidAmount } }">
            <template v-if="unpaidAmount === debit">
              <p>{{ formatMoney(debit) }}</p>
            </template>
            <template v-else>
              <p>{{ `${formatMoney(debit)} / ${formatMoney(unpaidAmount)}` }}</p>
            </template>
          </template>
          <template #cell-paidAmount="{ rowData: { paidAmount } }">
            <template v-if="paidAmount">
              <p>{{ formatMoney(paidAmount) }}</p>
            </template>
            <template v-else>
              <p>-</p>
            </template>
          </template>
          <template #cell-actions="{ rowData }">
            <TableActions :items="actionItems" :data="rowData"></TableActions>
          </template>
        </Table>
      </div>
      <DocumentModal
        v-if="selectedDocumentId"
        visible
        :document-id="selectedDocumentId"
        @close="selectedDocumentId = null"
      />
      <PartialPaymentModal
        v-if="billingToPartiallyPay"
        :billing="billingToPartiallyPay"
        @close="billingToPartiallyPay = null"
        @partial-payment="handlePartialPayment"
      />
    </div>
    <template #footer>
      <span class="d-flex justify-content-between align-items-center h-50">
        <span class="text-end">
          <p class="text-typography-primary">{{ translate('totalDebtAfterUpdate') }}</p>
          <h2 v-if="!loading" class="text-typography-primary">{{ formatMoney(debitAmountLeftAfterUpdate) }}</h2>
        </span>
        <el-pagination
          v-if="unpaidBillings.length > PAGE_LIMIT"
          class="px-0"
          small
          layout="prev, pager, next, jumper"
          background
          :current-page.sync="currentPage"
          :page-size="PAGE_LIMIT"
          :page-count="Math.ceil(unpaidBillings.length / PAGE_LIMIT)"
        />
      </span>
      <div class="d-flex justify-content-end h-50">
        <div class="d-flex align-items-end">
          <Button type="secondary" @click="close">
            {{ $t('commons.cancel') }}
          </Button>
          <Button @click="submit">
            {{ translate('submitButton') }}
          </Button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { isNil, reject } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';
import Big from 'big.js';

import { Button, Table, TableActions } from '@/modules/core';
import { CloseIcon, KebabIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useUnpaidBillings, useBillings, useCreatePayment } from '../../compositions';
import { formatDate } from '../../tools/formatters';
import PartialPaymentModal from './PartialPaymentModal';

const PAGE_LIMIT = 20;

const TABLE_HEADER = {
  BILLING_DATE: 'billingDate',
  EVENT_TYPE: 'eventType',
  REFERENCE: 'reference',
  DEBIT: 'debit',
  PAID_AMOUNT: 'paidAmount',
  ACTIONS: 'actions',
};

const ACTION = {
  SELECT_ALL: 'selectAll',
  UNSELECT_ALL: 'unselectAll',
};

export default {
  components: { DocumentModal, PartialPaymentModal, Button, Table, CloseIcon, KebabIcon, TableActions },
  props: {
    supplier: { type: Object, required: true },
    businessId: { type: String, required: true },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { formatToCurrency } = useCurrency();

    const currentPage = ref(1);
    const {
      createPayment,
      loading: createPaymentLoading,
      onDone: onCreatePaymentDone,
      onError: onCreatePaymentError,
    } = useCreatePayment();
    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: props.businessId, supplierId: props.supplier.id }))
    );
    const sortedUnpaidBillings = computed(() =>
      [...unpaidBillings.value].sort((a, b) => new Date(b.date) - new Date(a.date))
    );

    const paginatedUnpaidBillings = computed(() =>
      sortedUnpaidBillings.value.slice((currentPage.value - 1) * PAGE_LIMIT, currentPage.value * PAGE_LIMIT)
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        businessId: props.businessId,
        ids: paginatedUnpaidBillings.value.map(({ billingId }) => billingId),
      }))
    );

    onCreatePaymentDone(() => {
      $message.success($i18n.t('payment.balancePaymentModal.messages.createPaymentDone'));
      emit('balance-payment-create');
      emit('close');
    });
    onCreatePaymentError(() => $message.error($i18n.t('payment.balancePaymentModal.messages.createPaymentError')));

    const loading = computed(() => unpaidBillingsLoading.value || billingsLoading.value || createPaymentLoading.value);

    const paginatedUnpaidBillingsWithDocuments = computed(() =>
      !billings.value.length
        ? []
        : paginatedUnpaidBillings.value.map((unpaidBilling) => {
            const relevantBilling = billings.value.find((billing) => billing.id === unpaidBilling.billingId);

            return {
              ...unpaidBilling,
              document: relevantBilling?.eventReferences?.[0]?.document,
            };
          })
    );

    const billingsToPay = ref([]);
    const selectedBillingIds = ref([]);
    const rowSelection = computed({
      get: () =>
        paginatedUnpaidBillingsWithDocuments.value
          .filter((unpaidBilling) => selectedBillingIds.value.includes(unpaidBilling.billingId))
          .map((unpaidBilling) => paginatedUnpaidBillingsWithDocuments.value.indexOf(unpaidBilling)),
      set: (indexes) => {
        selectedBillingIds.value = selectedBillingIds.value.filter((billingId) =>
          paginatedUnpaidBillingsWithDocuments.value.every((unpaidBilling) => unpaidBilling.billingId !== billingId)
        );
        const selectedUnpaidBillings = paginatedUnpaidBillingsWithDocuments.value
          .filter((_, idx) => indexes.includes(idx))
          .map(({ billingId }) => billingId);

        billingsToPay.value = billingsToPay.value.filter(({ billingId }) =>
          selectedUnpaidBillings.every((unpaidBillingId) => unpaidBillingId !== billingId)
        );

        selectedBillingIds.value = [...selectedBillingIds.value, ...selectedUnpaidBillings];
      },
    });

    const billingToPartiallyPay = ref(null);

    const actionItems = [
      {
        title: $i18n.t(`payment.balancePaymentModal.actions.partialPayment`),
        icon: 'PartialIcon',
        clickhandler: ({ billingId }) => {
          const billing = billings.value.find((b) => b.id === billingId);
          const { paidAmount, totalAmount } = paginatedUnpaidBillingsWithDocuments.value.find(
            (b) => b.billingId === billingId
          );

          const paymentData = {
            remainderAmount: totalAmount - paidAmount,
          };
          billingToPartiallyPay.value = { ...billing, paymentData };
          selectedBillingIds.value = selectedBillingIds.value.filter((id) => id !== billingId);
        },
      },
      {
        title: $i18n.t(`payment.balancePaymentModal.actions.fullPayment`),
        icon: 'CompleteIcon',
        clickhandler: ({ billingId }) => {
          selectedBillingIds.value = [...selectedBillingIds.value, billingId];
        },
      },
    ];

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      createPayment,
      formatDate,
      formatMoney,
      PAGE_LIMIT,
      loading,
      unpaidBillings: sortedUnpaidBillings,
      currentPage,
      rowSelection,
      billingsToPay,
      selectedBillingIds,
      selectedDocumentId: ref(null),
      paginatedUnpaidBillingsWithDocuments,
      actionItems,
      billingToPartiallyPay,
    };
  },
  computed: {
    selectedAmount() {
      return this.unpaidBillings
        .filter(({ billingId }) => this.selectedBillingIds.includes(billingId))
        .reduce((sum, { unpaidAmount }) => sum + unpaidAmount, 0);
    },
    debitAmountLeftAfterUpdate() {
      const partialPayments = this.billingsToPay.reduce((sum, { amount }) => sum + amount, 0);
      return (
        this.unpaidBillings.reduce((sum, { unpaidAmount }) => sum + unpaidAmount, 0) -
        this.selectedAmount -
        partialPayments
      );
    },
    columns() {
      return [
        {
          header: this.translate('table.headers.billingDate'),
          key: TABLE_HEADER.BILLING_DATE,
        },
        {
          header: this.translate('table.headers.eventType'),
          key: TABLE_HEADER.EVENT_TYPE,
        },
        {
          header: this.translate('table.headers.reference'),
          key: TABLE_HEADER.REFERENCE,
        },
        {
          header: this.translate('table.headers.debit'),
          key: TABLE_HEADER.DEBIT,
        },
        {
          header: this.translate('table.headers.paidAmount'),
          key: TABLE_HEADER.PAID_AMOUNT,
        },
        {
          header: '',
          key: TABLE_HEADER.ACTIONS,
        },
      ];
    },
    tableData() {
      return this.paginatedUnpaidBillingsWithDocuments.map(
        ({ billingId, date, totalAmount, unpaidAmount, document }) => {
          const partialBillingToPay = this.billingsToPay.find((b) => b.billingId === billingId);
          const fullBillingToPay = this.unpaidBillings.find(
            ({ billingId: unpaidBillingId }) =>
              unpaidBillingId === billingId && this.selectedBillingIds.includes(unpaidBillingId)
          );

          const { amount: partialAmount } = partialBillingToPay || {};
          const { unpaidAmount: fullAmount } = fullBillingToPay || {};
          const amount = partialAmount ?? fullAmount;

          return {
            [TABLE_HEADER.BILLING_DATE]: date,
            [TABLE_HEADER.REFERENCE]: { documentNumber: document?.documentNumber, documentId: document?.id },
            [TABLE_HEADER.DEBIT]: totalAmount,
            unpaidAmount,
            documentType: document?.type,
            billingId,
            paidAmount: amount ?? 0,
          };
        }
      );
    },
  },
  methods: {
    handlePartialPayment({ billingId, amount }) {
      const partiallyPaidBillingIndex = this.billingsToPay.findIndex((billing) => billing.billingId === billingId);
      if (partiallyPaidBillingIndex !== -1) this.billingsToPay[partiallyPaidBillingIndex].amount = amount;
      else this.billingsToPay = [...this.billingsToPay, { billingId, amount }];
      this.billingToPartiallyPay = null;
    },
    handleAction(command) {
      switch (command) {
        case ACTION.SELECT_ALL:
          this.billingsToPay = [];
          this.selectedBillingIds = this.unpaidBillings.map(({ billingId }) => billingId);
          break;
        case ACTION.UNSELECT_ALL:
          this.billingsToPay = [];
          this.selectedBillingIds = [];
          break;
        default:
          break;
      }
    },
    close() {
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`payment.balancePaymentModal.${key}`);
    },
    async submit() {
      const confirm = await this.$confirm(
        this.$i18n.t(this.selectedBillingIds.length ? 'commons.areYouSure' : 'payment.balancePaymentModal.warning'),
        { type: 'warning' }
      )
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;

      const relevantBillings = this.unpaidBillings.filter(({ billingId }) =>
        this.selectedBillingIds.includes(billingId)
      );

      const partialRelevantBillings = this.billingsToPay.map(({ billingId, amount }) => {
        const unpaidBilling = this.unpaidBillings.find(
          ({ billingId: unpaidBillingId }) => unpaidBillingId === billingId
        );
        const { paidAmount } = unpaidBilling;
        return { billingId, unpaidAmount: amount, totalAmount: paidAmount + amount };
      });

      const totalAmount = [...relevantBillings, ...partialRelevantBillings]
        .reduce((sum, { totalAmount }) => sum.add(totalAmount), new Big(0))
        .toNumber();

      await this.createPayment({
        createParams: reject(isNil)({
          type: 'balance',
          initialBalance: true,
          supplierId: this.supplier.id,
          businessId: this.businessId,
          customerId: this.businessId,
          amount: Math.abs(totalAmount),
          isRefund: totalAmount < 0,
          transactions: [],
          billingLinks: [...relevantBillings, ...partialRelevantBillings].map(({ billingId, unpaidAmount }) => ({
            billingId,
            amount: unpaidAmount,
          })),
        }),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .balance-payment-modal {
  display: grid;
  grid-template-rows: 90px 1fr 109px;
  width: 55.8125rem;
  height: 80vh;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  overflow-y: hidden;
  padding: 1em;
}

::v-deep tr {
  .actions-btn {
    visibility: hidden;
  }

  &:hover .actions-btn {
    visibility: visible;
  }
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
</style>
